@import "./colors.scss";

button {
    background-color: $primary_black;
    color: $super_white;
    font-weight: 700;
    cursor: pointer;
}

button:focus {
    outline: none;
}

.basic {
    position: relative;
    border-radius: 22px;
    width: 414px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.62);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.62);

    @media screen and (max-width: 416px) {
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: 281px) {
        width: 280px;
        height: 100%;
    }
}

.calculator-container {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calculator-main {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.basic > .screen-container {
    background-color: $primary-bland;
    flex: 0.75;
    width: 100%;
    border-radius: 22px 22px 0 0;
    display: flex;
    flex-direction: column;
}

.basic > .screen-container > .screen-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    padding: 16px 16px 0;
}

.basic > .screen-container > .screen-top > .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 65%;
    margin-right: 12px;
    font-family: "Brush Script MT", cursive;

    > h2,
    h4,
    p {
        margin: 0;
    }

    > p {
        font-size: 32px;
        font-weight: bold;
    }

    > h4 {
        margin-top: -8px;
    }
}

.basic > .screen-container > .screen-top > .solar {
    width: 150px;
    height: 65%;
    border-radius: 4px;
    box-shadow: inset 5px 5px 8px rgba(0, 0, 0, 0.5);
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dd5c29+0,5c210a+11,5c210a+11,4b1601+98,81462f+100&0.77+21,1+87 */
    background: -moz-linear-gradient(
        top,
        rgba(221, 92, 41, 0.77) 0%,
        rgba(92, 33, 10, 0.77) 11%,
        rgba(90, 32, 9, 0.77) 21%,
        rgba(77, 23, 2, 1) 87%,
        rgba(75, 22, 1, 1) 98%,
        rgba(129, 70, 47, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        rgba(221, 92, 41, 0.77) 0%,
        rgba(92, 33, 10, 0.77) 11%,
        rgba(90, 32, 9, 0.77) 21%,
        rgba(77, 23, 2, 1) 87%,
        rgba(75, 22, 1, 1) 98%,
        rgba(129, 70, 47, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        rgba(221, 92, 41, 0.77) 0%,
        rgba(92, 33, 10, 0.77) 11%,
        rgba(90, 32, 9, 0.77) 21%,
        rgba(77, 23, 2, 1) 87%,
        rgba(75, 22, 1, 1) 98%,
        rgba(129, 70, 47, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c4dd5c29', endColorstr='#81462f',GradientType=0 ); /* IE6-9 */
}

.basic > .screen-container > .screen {
    flex: 1;
    margin: 16px;
    padding: 8px;
    height: 60px;
    border-radius: 4px;
    background: gray;
    border: 1px solid $primary_black;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: auto;
}

.basic > .middle-skinny {
    flex: 0.2;
    width: 100%;
    background-color: $primary-bland;
}

.basic > .basic-keys-container {
    background-color: $primary-bland;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: 24px 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.top {
    > button {
        border-radius: 4px;
        height: 65%;
    }
}

.row {
    display: flex;
    flex: 1;
    // height: 55px;
    margin: 0 24px;

    > button {
        flex: 1;
        margin: 4px;
    }
}

.small {
    margin: 0 0 0 24px;
}

.basic-keys {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    flex-wrap: wrap;
    height: 100%;
}

.basic-keys > .basic-keyboard {
    grid-template-rows: repeat(4, 1fr);
}

.basic-keys > .basic-arithmetic {
    grid-template-rows: repeat(2, 1fr);
}

.right-side {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 8px;
}

.basic-keyboard > .basic-key,
.basic-arithmetic > .basic-key {
    border: solid 1px $primary_black;
    border-radius: 4px;
    transform: skewX(-4deg);
    font-size: 42px;
    flex: 1;
}

.basic-arithmetic {
    flex: 0.5;
}

.basic-general {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.bottom-container {
    flex: 2;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin: 0 24px;

    .small.row:first-of-type {
        grid-row: 1;
    }

    .small:last-child {
        grid-row: 2;
    }

    .row {
        margin-left: 0;
    }

    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        grid-column: 1 / 4;
        grid-row: 1 / span 2;
    }

    > button {
        flex: 0.208;
        margin: 4px;

        @media screen and (max-width: 412px) {
            flex: 0.225;
        }
        @media screen and (max-width: 376px) {
            flex: 0.215;
        }
    }
}

.bottom-container > div > .small > .basic-key > .three {
    grid-column: 1;
    grid-row: 1;
}
.bottom-container > div > .small > .basic-key > .two {
    grid-column: 2;
    grid-row: 1;
}
.one {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
}

.plus {
    grid-column: 4;
    grid-row: 1 / span 2;
}

.red {
    background-color: $primary-power-red;
}
